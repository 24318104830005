import React, { useContext } from 'react';
import { FeatureBox, FeatureList, NewHero, Page, Section, Zip } from 'src/components';
import SessionContext from 'src/context/SessionContext';
import { Link } from 'gatsby';
import paths from 'src/constants/paths';

/**
 * Given a color, return whether we should be light or dark mode
 * @todo: moves this somewhere it can be shared
 */
function getColorMode(hex) {
  if (!hex) return '';

  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values from the hex color
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the relative luminance (perceived brightness)
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // If the luminance is greater than 0.5, return black, otherwise return white
  return luminance > 0.5 ? 'light' : 'dark';
}

/**
 */
const Partner = ({ pageContext }) => {
  const {
    name,
    partner,
    source: _source,
    colorLight,
    code,
    title,
    subtitle,
    logo,
    applyFilter,
    personas = [],
    color,
    color2,
    vertical,
    promo,
    website,
    cta,
    image = '/img/og/health.png',
    imageData,
    section1,
    section2,
    section3,
    bullets,
    dental,
  } = pageContext;

  const { setPartner, signupUrl, setVertical, setCode, source, setSource } =
    useContext(SessionContext);

  const benefits =
    bullets?.length > 0
      ? bullets
      : [
          'Tailored plan recommendations',
          'Custom benefits advisor',
          '1-on-1 support all-year long',
        ];

  if (setSource) {
    if (_source) setSource(_source);
    if (partner) setPartner(partner);
    if (code || _source || partner) setCode(code || _source || partner);
    if (vertical) setVertical(vertical);
  }

  const _color = color2 || color;

  const nameWithOptionalLink = website ? (
    <a href={website} target="_blank">
      {name?.toProperCase()}
    </a>
  ) : (
    name?.toProperCase()
  );

  const contactCta = (
    <>
      Prefer to talk to a human? <Link to={paths.CONTACT}>Get in touch.</Link>
    </>
  );

  return (
    <Page
      hideBanner
      navBorder={false}
      signupUrl={signupUrl}
      cta={cta}
      hideLinks
      metadata={{
        image: image,
        keywords: [source, partner, name, vertical, ...personas],
        canonical: `https://catch.co${paths.PARTNERS}/${partner}`,
        title: !!title ? title : `Catch ✕ ${name || partner}`,
        description:
          subtitle ||
          `Get dependable benefits. Save on ${
            dental ? 'health insurance, dental, and more.' : 'health insurance and more.'
          } For free.`,
      }}
    >
      <NewHero
        colorLight={colorLight}
        image={imageData}
        color={_color}
        logo={logo}
        applyFilter={applyFilter}
        eb={!logo && 'Health & Dental'}
        terms
        fine={promo}
        title={
          !!title
            ? title
            : !!name
              ? `Benefits for ${name?.toProperCase()} members`
              : 'Benefits with Catch'
        }
        subtitle={
          subtitle || (
            <>
              {nameWithOptionalLink} members get premium access to Catch. Save $400+/mo on
              ACA-compliant {dental ? 'health, dental and more.' : 'health plans and more.'}
            </>
          )
        }
      >
        <div className={getColorMode(colorLight || _color)}>
          <Zip />
        </div>
        <div style={{ paddingTop: 24 }}>{contactCta}</div>
      </NewHero>
      <Section small>
        <FeatureBox
          eb={section1?.pretitle || 'Shop'}
          title={section1?.title || `VIP access when you're part of ${name?.toProperCase()}`}
          subtitle={
            section1?.description ||
            `${name?.toProperCase()} members nationwide get the following at no cost to them:`
          }
          fullImage="explore.png"
          color={_color}
          ebColor={color}
        >
          <FeatureList items={benefits}></FeatureList>
          <div style={{ paddingTop: 48 }}>{contactCta}</div>
        </FeatureBox>
        <FeatureBox
          eb={section2?.pretitle || 'Select'}
          title={section2?.title || 'We do the research. You get the benefits.'}
          subtitle={
            section2?.description ||
            'Enter your ZIP to explore recommended plans or contact us to chat.'
          }
          fullImage="compare.png"
          color={_color}
          ebColor={color}
        >
          <Zip />
          <div style={{ paddingTop: 48 }}>{contactCta}</div>
        </FeatureBox>
        <FeatureBox
          eb={section3?.pretitle || 'Trust'}
          title={section3?.title || "What's the Catch? There is none."}
          subtitle={
            section3?.description ||
            'Catch is an official partner of HealthCare.gov, and supports ACA-compliant plans only. Catch is also licensed across the US, meaning we can work you in every state nationwide.'
          }
          fullImage="explore-detail.png"
          color={_color}
          ebColor={color}
        ></FeatureBox>
      </Section>
      <Section
        narrow
        title="Start shopping today."
        subtitle={`Explore ${
          dental ? 'health, dental and more.' : 'health plans and more.'
        } Plus, find tax credits you may have missed to lower your health insurance premiums.`}
        bg="var(--g-red)"
        inset
        dark
      >
        <Zip />
        <div style={{ paddingTop: 24 }}>{contactCta}</div>
      </Section>
    </Page>
  );
};

export default Partner;
